import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from '../../Services/config.js';
import Instance from '../../Services/Instance';
import { useParams } from 'react-router-dom';
import '../../Assets/Styles/Subscriptions.css';
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg"
import Footer from '../../Layouts/Footer';
import { v4 as uuidv4 } from 'uuid';
import { decryptData } from '../../Services/EncryptData.js';
import { DataContext } from '../../Services/DataContext';
import ReactPixel from 'react-facebook-pixel';



function NewSubscription() {
    // Déclaration des états
    const [error, setError] = useState('');
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement
    const [licenseToReserve, setLicenseToReserve] = useState({

        statusLicenseToReserve: false,
        subscriptionEmailKey: "",
        subscriptionPWKey: "",

    })


    // Récupération de l'identifiant du produit depuis les paramètres de l'URL
    const { id } = useParams();
    //Recuperation de l'id de la commande 


    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)

    const { subscriptionEmailKey, subscriptionPWKey, statusLicenseToReserve } = licenseToReserve;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLicenseToReserve({ ...licenseToReserve, [name]: value });
    };
    const handleChangeStatus = () => { setLicenseToReserve({ ...licenseToReserve, statusLicenseToReserve: !statusLicenseToReserve }) }


    // Effet de chargement initial pour récupérer les détails du produit
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                setProduct(result.data.product);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [id]);

    // Génération de la référence de commande
    function generateRefCommand() {
        const uniqueId = uuidv4();
        const refCommand = uniqueId.split('-').slice(0, 4).join('').substring(0, 12);
        return refCommand;
    }

    // Incrémentation de la quantité
    const increment = () => {
        if (quantity < 3) {
            setQuantity(quantity + 1);
        }
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    //Recupérer le prix du smart tv 
    const smarTvPrice = localStorage.getItem('smartTvPrice');
    let netflixType = localStorage.getItem("netflixType")
    let optionSub = "";

    const priceSub = smarTvPrice ? smarTvPrice : product.productPrice;
    if (netflixType) {
        optionSub = `- ${netflixType}`
    } else if (!netflixType && product.productName && product.productName.toLocaleLowerCase().includes("netflix") && !product.productName.includes("NETFLIX Pack Family") && !product.productName.includes("Netflix Duo")) {
        optionSub = "- Phone/Pc"
    }


    // Gestion de la demande de paiement
    const handlePaymentRequest = async () => {
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire
        try {
            setPaymentInProgress(true); // Définir l'état de la demande de paiement en cours
            let orderRef = generateRefCommand();
            let keyLicense = "";
            if (statusLicenseToReserve === true) {
                orderRef = `_LTR_${orderRef}` // LTC : License to Reserve 
                keyLicense = `Email : ${subscriptionEmailKey}\nMot de passe : ${subscriptionPWKey}`
            }

            const orderRequest = {
                item_name: `${product.productName} ${optionSub}`,
                item_price: quantity * priceSub,
                ref_command: orderRef,
                custom_field: {
                    subscriptionDate: new Date(),
                    subscriptionName: `${product.productName} ${optionSub}`,
                    subscriptionRef: orderRef,
                    subscriptionPrice: quantity * priceSub,
                    subscriptionKey: keyLicense,
                    policy: "",
                    subscriptionType: product.productType,
                    CategoryName: product.productCategory,
                    productTypeSmart: optionSub,
                    productId: product._id,
                    productImage: product.productImage,
                    subscriptionQuantity: quantity,
                    subscriptionNumber: quantity,
                    userId: user._id,
                    userFirstName: user.firstName,
                    userLastName: user.lastName,
                    userEmail: user.email,
                    userNumberPhone: user.numberPhone,
                    userCountry: user.country || 'Senegal',
                    urlImage: `${config.img_url}/${product.productImage}`
                }
            };

            const _response = await Instance.post(`${config.api_url}/payment`, orderRequest);
            // console.log("++++++++++",_response);
            if (_response.data.jsonResponse.success) {
                const paymentUrl = _response.data.jsonResponse.redirect_url;
                // console.log("new sub");

                // Appeler la deuxième fonction avec orderRef comme argument
                await handleSubscriptionRequest(orderRequest.custom_field);

                //supprimer le prix du smarttv après avant le redirection pour le paiement

                // Retourner l'URL de paiement
                window.location.href = paymentUrl
            }
        } catch (error) {
            console.error('Erreur lors de la demande de paiement :', error);
            // Gérer les erreurs de manière appropriée
            throw error;
        } finally {
            setPaymentInProgress(false); // Définir l'état de la demande de paiement terminée
        }
    };

    // Fonction pour effectuer la deuxième requête pour la demande d'abonnement
    const handleSubscriptionRequest = async (orderRequest) => {
        try {
            const subscriptionResponse = await Instance.post(`${config.api_url}/new-subscription`, orderRequest);
            // console.log('Subscription Response:', subscriptionResponse);

            // Retourner la réponse de la deuxième requête
            return subscriptionResponse;
        } catch (error) {
            console.error('Erreur lors de la demande d\'abonnement :', error);
            // Gérer les erreurs de manière appropriée
            throw error;
        }
    };

    // console.log(product)
    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>
            <div className='subscription-content-layout interne-subscription'>
                <h3>Détails de la commande</h3>
                <div className='img-product'>
                    <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                </div>

                <div className='subscription-options'>

                    <div className='subscription-options-details'>

                        <div>
                            <h3> {product.productName} {optionSub}</h3>
                            <h3> FCFA {product.productPrice} </h3>
                        </div>

                        <div className='counter'>
                            <button onClick={decrement} className='counter-params'>
                                -
                            </button>
                            <button className='counter-params' id='quantity'>
                                {quantity}
                            </button>
                            <button onClick={increment} className='counter-params'>
                                +
                            </button>
                        </div>
                    </div>

                </div>

                <hr />

                <div className='subscription-options-details sub-total'>
                    <p>Sub Total </p>
                    {!smarTvPrice && <h3> {product.productPrice * quantity} FCFA </h3>}
                    {smarTvPrice && <h3> {smarTvPrice * quantity} FCFA </h3>}
                </div>

                <div className='subscription-options-details frais-payment'>
                    <p>Frais Paiment </p>
                    <h3> 2%</h3>
                </div>

                <div className='subscription-options-details sub-total'>
                    <p> Total </p>
                    {!smarTvPrice && <h3> {(product.productPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                    {smarTvPrice && <h3> {(smarTvPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                </div>

                <div className=''>
                    {statusLicenseToReserve === false && product.productCategory !== "Cartes Cadeaux et Jeux Video" && <p className='sub-inprogress' onClick={() => handleChangeStatus()} >Avez vous un compte existant que vous souhaitez Conserver ?</p>}
                </div>
                {
                    statusLicenseToReserve === true &&
                    (

                        <div className="form-reserve-license" >
                            <p className='sub-inprogress' onClick={() => handleChangeStatus()}>Je préfére avoir de nouveaux Accès !!!</p>
                            <p> Veuillez indiquer ici les identifiants du compte que vous souhaitez renouveler.</p>
                            <p style={{ "color": "red", "fontSize": "13px" }}> Attention! Ce compte est le compte de votre abonnement que vous souhaitez rénouveler et non  l'email avec lequel vous vous connecter sur l'application Senpremium.</p>
                            <div className='details-form-reserve-license'>
                                <label>Email du compte existant à renouveler</label>
                                <input
                                    name="subscriptionEmailKey"
                                    value={subscriptionEmailKey}
                                    type="email"
                                    onChange={(e) => handleChange(e)}

                                />
                            </div>
                            <div className='details-form-reserve-license'>
                                <label>Mot de passe du compte existant à renouveler</label>
                                <input
                                    name="subscriptionPWKey"
                                    value={subscriptionPWKey}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    )
                }

                <button onClick= {handlePaymentRequest} className='validate-subscription-btn' id='btn-payer' disabled={paymentInProgress}>
                    {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                </button>

            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    );
}

export default NewSubscription;
