import React, { useState } from 'react';
import Instance from '../../Services/Instance';
import config from '../../Services/config.js';
import logo from '../../Assets/Imgs/icons/logo-senpremium2.svg';
import { Link } from 'react-router-dom';
import '../../Assets/Styles/Connection.css';

const ReqResetPassWord = () => {
    const [data, setData] = useState({ email: ''});
    const [error, setError] = useState('');
    const [resReq, setResReq] = useState('');

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.api_url}/link-reset-password`;
            const { data: response } = await Instance.post(url, data);

            if (response) {
                const message = response.message
                setResReq(message)
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            setError(error);
        }
    };

    return (
        <div className='layout-signin'>
            <div className='logo'>
                <Link to='/'>
                    <img src={logo} alt='logo' />
                </Link>
            </div>
            <div className='form-req-reset-password'>
                <form onSubmit={handleSubmit}>
                    <div className='parent-form-input'>
                        <label htmlFor='email'>Email:</label>
                        <input
                            className='input'
                            type='email'
                            name='email'
                            id='email'
                            value={data.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <Link onClick={handleSubmit} className='btn-form-connection' id='btn-resetpw'>
                        Réinitialiser le mot de passe
                    </Link>
                    {resReq && <p> {resReq} </p>}
                </form>
            </div>
            <div>
                {error && <h6 style={{ color: 'red' }}>{error.response.data.message}</h6>}
            </div>
        </div>
    );
};


export default ReqResetPassWord