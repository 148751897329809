import { useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import logo from '../../Assets/Imgs/icons/logo senpremium.png';
// import google from "../../Assets/Imgs/icons/icon_google.png"
import home from "../../Assets/Imgs/templates/banner_2.png"
import "../../Assets/Styles/Connection.css"
import { Link } from "react-router-dom";



const SignUp = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    numberPhone: "",
    email: "",
    password: "",
    role: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const checkbox = document.getElementById("checkbox")

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkbox.checked) {

      try {
        const url = `${config.api_url}/signup`;

        const { data: response } = await Instance.post(url, data);
        // console.log(response.message);
        window.location = "/signin";
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
        setError(error);
      }

    } else {
      alert("Vous devez d'abord accepter les CGU de Senpremium avant de vous inscrir.");
    }

  };
  return (
    <div className="layout-signin">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="form-signin ">
        <form onSubmit={handleSubmit}>
          {/*<div className="card-title-signin title-google ">
            <img src={google} alt="google" />
            <h3> Se Connecter avec Google</h3>
          </div>
          <div className="ou">
            <hr />
            <p>ou</p>
            <hr />
  </div>*/}
          <div className="full-name">
            <div>
              <label htmlFor="pseudo">Prénom</label>
              <input
                className="input"
                type="text"
                id="firstName"
                name="firstName"
                value={data.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="pseudo">Nom</label>
              <input
                className="input"
                type="text"
                id="lastName"
                name="lastName"
                value={data.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="pseudo">Téléphone</label>
            <input
              className="input"
              type="text"
              id="numberPhone"
              name="numberPhone"
              value={data.numberPhone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              className="input"
              type="email"
              id="email"
              name="email"
              value={data.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Mot de passe</label>
            <input
              className="input"
              type="password"
              id="password"
              name="password"
              value={data.password}
              onChange={handleChange}
              required
            />
          </div>
          <div id='cgu'>
            <div className='checkbox'>
              <input
                id='checkbox'
                name="CGU"
                type="checkbox"
                defaultChecked
              />
              <label> <Link to="/help-center/CGU"> En vous inscrivant vous acceptez les CGU de Senpremium.</Link></label>

            </div>
            <p>Vos informations personnelles  seront utilisées pour valider vos commandes. </p>
          </div>         <button className='btn-form-connection' >
            S'inscrire
          </button>

        </form>

        <div className="container-right-signin container-right-description">
          <div className="card-title-signup ">
            <div className="card-title-signin">
              <h3> Ak SenPremium</h3>
            </div>
            <p>
              Rejoignez la meilleure application d'abonnements et profitez pleinement de vos services préférés en partageant ou rejoignant un service.
              <br />
              Spotify, Netflix, Amazon, Scribd, Appel Music, Mircosoft 365, Udemy, Iptv, etc...
            </p>

            <img src={home} alt="img-signin" />

          </div>
        </div>
      </div>
      <div>
        {
          error &&
          (<h1 style={{ color: "red" }}>{error.response.data.message}</h1>)
        }
      </div>
    </div>
  );
};

export default SignUp;



