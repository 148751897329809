import React, { useState } from 'react';
import img1 from "../../Assets/Imgs/templates/sub1.png";
import img2 from "../../Assets/Imgs/templates/sub2.png";
import img3 from "../../Assets/Imgs/templates/sub3.png";
import img4 from "../../Assets/Imgs/templates/sub4.png";
import img1mobile from "../../Assets/Imgs/templates/sub1mobile.png";
import img2mobile from "../../Assets/Imgs/templates/sub2mobile.png";
import img3mobile from "../../Assets/Imgs/templates/sub3mobile.png";
import img4mobile from "../../Assets/Imgs/templates/sub4mobile.png";
import "../../Assets/Styles/Share.css"
import zigzag from "../../Assets/Imgs/icons/zigzag.svg"

function HowSubscription() {
    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    return (
        <div className='HowShare'>

            <div className="sidebar">

                <div className='btn-how-it-work' id={activeButton === 1 ? "btn-active" : ""} onClick={() => handleButtonClick(1)}>
                    <h1 className='choice-number'>1</h1>
                    <div className='details-choice'>
                        <h2>Choix</h2>
                        <p id='details'>
                            Choissez l’abonnement qui vous convient sur la large Game de Senpremium
                        </p>
                    </div>
                </div>

                <div className='btn-how-it-work' id={activeButton === 2 ? "btn-active" : ""} onClick={() => handleButtonClick(2)}>
                    <h1 className='choice-number'>2</h1>
                    <div className='details-choice'>
                        <h2> Produit </h2>
                        <p id='details'>
                            Vous avez ici tous les détails concernant le produit que vous avez choisi
                        </p>
                    </div>
                </div>

                <div className='btn-how-it-work' id={activeButton === 3 ? "btn-active" : ""} onClick={() => handleButtonClick(3)}>
                    <h1 className='choice-number'>3</h1>
                    <div className='details-choice'>
                        <h2> Infos </h2>
                        <p id='details'>
                            Passez vos informations puis Choissez les options de votre abonnement selon vos besoins
                        </p>
                    </div>
                </div>

                <div className='btn-how-it-work' id={activeButton === 4 ? "btn-active" : ""} onClick={() => handleButtonClick(4)}>
                    <h1 className='choice-number'>4</h1>
                    <div className='details-choice'>
                        <h2> Payment </h2>
                        <p id='details'>
                            Effectuez Votre paiement en toute sécurité selon le moyen de paiement qui vous convient
                        </p>
                    </div>
                </div>

            </div>
            <div className='container-zigzag'>
                <img src={zigzag} alt="zigzag" className='zigzag' />
            </div>
            <img src={activeButton === 1 ? img1 : activeButton === 2 ? img2 : activeButton === 3 ? img3 : img4} alt={`img${activeButton}`} className='image-container' />
            <img src={activeButton === 1 ? img1mobile : activeButton === 2 ? img2mobile : activeButton === 3 ? img3mobile : img4mobile} alt={`img${activeButton}`} className='image-container-mobile' />
        </div>
    );
}

export default HowSubscription;
