import React from 'react'
import CustomListBlog from '../Layouts/CustomListBlog';
import Footer from '../Layouts/Footer';
import NavBar from '../Layouts/NavBar';
import "../Assets/Styles/Blog.css"
import iconWow from "../Assets/Imgs/icons/icon-super.svg"
import iconPlus from "../Assets/Imgs/icons/icon-plus.svg"
import iconShareDeco from "../Assets/Imgs/icons/icon-share-deco.svg"

function Blog() {
  return (
    <div className='blog'>
      <NavBar />
      <div className='blog-banner'>
        <div className='top-banner'>
          <div className='imgs-deco'>
            <img src={iconWow} alt="img-deco" />
            <img src={iconPlus} alt="img-deco" />
          </div>
          <h1> <span id='_services'> +100 Services</span> <span id='dispo'>Disponibles</span> <br /> pour le <span id='abonnement'>Co-Abonnement</span> </h1>
          <img src={iconShareDeco} alt="img-deco" className='icon-share-deco' />
        </div>
        <CustomListBlog />

      </div>
      <div className='list-blog'>
      </div>
      <Footer />
    </div>
  )
}

export default Blog