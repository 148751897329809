import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({isAuthenticated, children }) => {
  const location = useLocation();

  if (!isAuthenticated) {
    alert('Vous devez vous connecter pour accéder à cette page!');
    const fullPath = location.pathname + location.search;
    return <Navigate to="/signin" replace state={{ from: fullPath }} />;
  }

  return <div>{children}</div>;
};

export default ProtectedRoute;
