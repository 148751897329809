import React, { useContext } from 'react'
import "../Assets/Styles/Blog.css"
import { Link } from 'react-router-dom'
import config from "../Services/config.js"
import { DataContext } from '../Services/DataContext'

function CustomListBlog() {
    const { posts } = useContext(DataContext)
    // Fonction pour découper le texte en fonction du nombre de mots
    const truncateText = (text, limit) => {
        const words = text.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        }
        return text;
    };
    return (

        <div className='blog-posts'>
            {
                posts.map((post) => (
                    <div className='card-post'>
                        <img src={`${config.img_url}/${post.productImage}`} alt="chatgpt" className='img-card-post' />
                        <div className='description-card'>
                            <h3 className='title-card'> {post.title} </h3>
                            <p>{truncateText(post.content, 50)}</p>
                            <Link to={`/blog/post/${post._id}/#post-content`}> Lire Plus</Link>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CustomListBlog ;