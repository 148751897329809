/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef } from "react";
import '../../Assets/Styles/Scrolling.css';
import { Link } from 'react-router-dom';
import listElementsScrolling from "./ListElementsScrolling"

const InfiniteScrollAnimation = () => {
    const carouselTrackRef = useRef(null);

    useEffect(() => {
        const carouselTrack = carouselTrackRef.current;
        let autoScrollInterval;

        const startAutoScroll = () => {
            autoScrollInterval = setInterval(() => {
                // Utiliser scrollLeft et scrollWidth pour un défilement fluide
                if (carouselTrack.scrollLeft >= carouselTrack.scrollWidth / 2) {
                    carouselTrack.scrollLeft = 0; // Réinitialiser à zéro
                } else {
                    carouselTrack.scrollLeft += 1; // Ajustez la vitesse ici
                }
            }, 10); // Ajustez l'intervalle ici
        };

        const stopAutoScroll = () => {
            clearInterval(autoScrollInterval);
        };

        startAutoScroll();

        carouselTrack.addEventListener("mouseenter", stopAutoScroll);
        carouselTrack.addEventListener("mouseleave", startAutoScroll);
        // Nettoyer les écouteurs d'événements et l'intervalle de défilement automatique
        return () => {
            carouselTrack.removeEventListener("mouseenter", stopAutoScroll);
            carouselTrack.removeEventListener("mouseleave", startAutoScroll);
            stopAutoScroll();
        };
    }, []);

    const subs = Object.keys(listElementsScrolling).map((key) => listElementsScrolling[key]);
    // console.log(subs);
    return (
        <div className="scroller " ref={carouselTrackRef}>
            <div className="scroller__inner ">
                {
                    subs.concat(subs).map((item, index) => (
                        <div className='list-elements-scroll ' key={index}>
                            <div id={item.id_style} className='custom-list-scroll-card'>
                                <div className='item-top'>
                                    <img src={item.categoryImage} alt="img" />
                                    <h4>{item.productName}</h4>
                                </div>
                                <hr />
                                <div className='item-center'>
                                    <h2>{item.productPrice} FCFA </h2>
                                </div>
                                <div className='item-bottom link-scroll_' >
                                    {
                                        (item.productCategory === "Cartes Cadeaux et Jeux Video") ?
                                            <Link to={`/subscription/${item._id}`} className='link-scroll _link_scroll' id='btn-banner'> Acheter → </Link>
                                            :
                                            <Link to={`/subscription/${item._id}`} className='link-scroll _link_scroll' id='btn-banner'> S'abonner → </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default InfiniteScrollAnimation;
