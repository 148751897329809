import React, { useState } from 'react';
import img1 from "../../Assets/Imgs/templates/share1.png";
import img2 from "../../Assets/Imgs/templates/share2.png";
import img3 from "../../Assets/Imgs/templates/share3.png";
import img4 from "../../Assets/Imgs/templates/share4.png";
import img1mobile from "../../Assets/Imgs/templates/share1.jpeg";
import img2mobile from "../../Assets/Imgs/templates/share4.jpeg";
import img3mobile from "../../Assets/Imgs/templates/share3.jpeg";
import img4mobile from "../../Assets/Imgs/templates/share2.jpeg";
import "../../Assets/Styles/Share.css"
import zigzag from "../../Assets/Imgs/icons/zigzag.svg"

function HowShare() {
    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    return (
        <div className='HowShare'>

            <div className="sidebar">

                <div className='btn-how-it-work' id={activeButton === 1 ? "btn-active" : ""} onClick={() => handleButtonClick(1)}>
                    <h1 className='choice-number'>1</h1>
                    <div className='details-choice'>
                        <h2>Connexion</h2>
                        <p id='details'>
                            Pour pouvoir faire un partage il faut vous dabord connecter. Si vous n'avez pas de Compte vous pouvez en créer un
                        </p>
                    </div>
                </div>

                <div className='btn-how-it-work' id={activeButton === 2 ? "btn-active" : ""} onClick={() => handleButtonClick(2)}>
                    <h1 className='choice-number'>2</h1>
                    <div className='details-choice'>
                        <h2> Home </h2>
                        <p id='details'>
                            Une fois connecté, Accédez à la barre de navigation et appuyer  sur Partage pour accéder àla page de Partage
                        </p>
                    </div>
                </div>

                <div className='btn-how-it-work' id={activeButton === 3 ? "btn-active" : ""} onClick={() => handleButtonClick(3)}>
                    <h1 className='choice-number'>3</h1>
                    <div className='details-choice'>
                        <h2> Compte </h2>
                        <p id='details'>
                            Une fois sur la page de Partage de votre compte , appuye sur boutton Partage
                        </p>
                    </div>
                </div>

                <div className='btn-how-it-work' id={activeButton === 4 ? "btn-active" : ""} onClick={() => handleButtonClick(4)}>
                    <h1 className='choice-number'>4</h1>
                    <div className='details-choice'>
                        <h2> Infos </h2>
                        <p id='details'>
                            IL ne vous reste qu'a passez les différents informations de l'élement que vous compter partager.
                        </p>
                    </div>
                </div>
            </div>

            <div className='container-zigzag'>
                <img src={zigzag} alt="zigzag" className='zigzag' />
            </div>

            <img src={activeButton === 1 ? img1 : activeButton === 2 ? img2 : activeButton === 3 ? img3 : img4} alt={`img${activeButton}`} className='image-container' />
            <img src={activeButton === 1 ? img1mobile : activeButton === 2 ? img2mobile : activeButton === 3 ? img3mobile : img4mobile} alt={`img${activeButton}`} className='image-container-mobile' />

        </div>
    );
}

export default HowShare;
