import React, { useContext, useEffect, useState } from 'react';
import "../../Assets/Styles/Home.css";
import { DataContext } from '../../Services/DataContext';
import CustomListLayout from '../../Layouts/CustomListLayout';
import Footer from "../../Layouts/Footer";
import iconWow from "../../Assets/Imgs/icons/icon-super.svg";
import iconPlus from "../../Assets/Imgs/icons/icon-plus.svg";
import iconShareDeco from "../../Assets/Imgs/icons/icon-share-deco.svg";
import listElementsScrolling from '../Home/ListElementsScrolling';
import iconMusic from "../../Assets/Imgs/icons/icon-music2.svg";
import iconCArteCadeau from "../../Assets/Imgs/icons/icon-carte-cadeau.svg";
import iconEducation from "../../Assets/Imgs/icons/icon-education2.svg";
import iconSecurity from "../../Assets/Imgs/icons/icon-security2.svg";
import iconLogiciel from "../../Assets/Imgs/icons/icon-app2.svg";
import iconVideo from "../../Assets/Imgs/icons/icon-tv2.svg";
import iconDesign from "../../Assets/Imgs/icons/icon-design.svg";
import search from "../../Assets/Imgs/icons/search-svgrepo-com (1).svg";
import CategoriesScroll from './CategoriesScroll';

function BannerProduct() {
    const { products, video, music, services, securite, design, carteCadeauEtJeux, education, logiciel } = useContext(DataContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeSection, setActiveSection] = useState('');

    window.scrollTo(0, 0);

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.categories-product-elements');
            let currentSection = '';

            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                if (window.pageYOffset >= sectionTop - 50) {
                    currentSection = section.getAttribute('id');
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const filteredProducts = products.filter(product => {
    //     return product.productName.toLowerCase().includes(searchTerm.toLowerCase()) || product.productType.toLowerCase().includes(searchTerm.toLowerCase());
    // });

    const filteredProducts = products.filter(product => {
        const searchTermWords = searchTerm.toLowerCase().split(" ");
        const productName = product.productName.toLowerCase();
        const productType = product.productType.toLowerCase();
    
        return searchTermWords.every(word => 
            productName.includes(word) || productType.includes(word)
        );
    });
    
    // Fonction pour mélanger un tableau (Fisher-Yates)
    const shuffleArray = (array) => {
        let shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };
    let mixProducts = shuffleArray(products)
    mixProducts = mixProducts.filter((product) => !product.productName.toLowerCase().includes("iptv"))
    // console.log(video);

    //Afficher ces produits en premier avec les videos
    const priorityProducts = [
        'Disney Plus 1Mois',
        // 'IPTV premium 1Mois',
        'Prime Video 1Mois',
        'Netflix 1 écran 1Mois',
        'NETFLIX 1 écran 2Mois',
        'Crunchyroll 1Mois',
    ];

    const priority = video.filter(product => priorityProducts.includes(product.productName));
    const others = video.filter(product => !priorityProducts.includes(product.productName) && product && !product.productName.toLowerCase().includes("iptv"));
    const shuffledOthers = shuffleArray(others);
    const newArrayVideo = ([...priority, ...shuffledOthers]);

    const topMusic = [
        "Spotify 3Mois",
        "Deezer 3Mois",
        "Spotify 1Mois",
        "Apple Music 3Mois",
    ];

    //faire la mm chose avec music
    const priorityMusic = music.filter(product => topMusic.includes(product.productName));
    const othersMusic = music.filter(product => !topMusic.includes(product.productName));
    // const shuffledOthers = shuffleArray(others);
    const newArrayMusic = ([...priorityMusic, ...othersMusic]);

    // console.log(others);
    return (
        <div className="banner banner-product">
            <div className='layout-banner-product'>

                <div className='layout-banner'>
                    <div className='top-banner'>
                        <div className='imgs-deco'>
                            <img src={iconWow} alt="img-deco" />
                            <img src={iconPlus} alt="img-deco" />
                        </div>
                        <h1> <span id='_services'> + 100 Services</span> <span id='dispo'>Disponibles;</span>  abonnements,  <span id='abonnement'>cartes cadeaux, jeux, </span> et bien  encore plus.</h1>
                        <img src={iconShareDeco} alt="img-deco" className='icon-share-deco' />
                    </div>
                    <div className='search-subscription'>
                        <input
                            type='text'
                            placeholder='Rechercher un abonnement'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className='content-icon-search'>
                            <img src={search} alt='search icon' className='icon-search' />
                        </div>
                        {
                            searchTerm &&
                            (<div className="popup-overlay" onClick={() => setSearchTerm('')}>
                                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                                    <CustomListLayout listItems={filteredProducts} />
                                </div>
                            </div>)
                        }
                    </div>

                </div>

                <CategoriesScroll setActiveSection={setActiveSection} />

                <div className='frequently-product'>
                    <h1>Les plus Populaires</h1>
                    <CustomListLayout listItems={listElementsScrolling} />
                </div>

            </div>

            <div className='categories-product'>
                <div id='products' className={`categories-product-elements ${activeSection === 'products' ? 'active' : ''}`}>
                    <h1>Tous les produits</h1>
                    <CustomListLayout listItems={mixProducts} />
                </div>

                <div id='video' className={`categories-product-elements ${activeSection === 'video' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Streaming Vidéo</h1>
                        <img src={iconVideo} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={newArrayVideo} />
                </div>

                <div id='cartes' className={`categories-product-elements ${activeSection === 'cartes' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Cartes Cadeaux Et Jeux</h1>
                        <img src={iconCArteCadeau} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={carteCadeauEtJeux} />
                </div>

                <div id='music' className={`categories-product-elements ${activeSection === 'music' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Musique</h1>
                        <img src={iconMusic} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={newArrayMusic} />
                </div>

                <div id='design' className={`categories-product-elements ${activeSection === 'design' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Design</h1>
                        <img src={iconDesign} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={design} />
                </div>

                <div id='security' className={`categories-product-elements ${activeSection === 'security' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Securité</h1>
                        <img src={iconSecurity} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={securite} />
                </div>

                <div id='services' className={`categories-product-elements ${activeSection === 'services' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Services</h1>
                        <img src={iconLogiciel} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={services} />
                </div>

                <div id='education' className={`categories-product-elements ${activeSection === 'education' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Education</h1>
                        <img src={iconEducation} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={education} />
                </div>

                <div id='logiciel' className={`categories-product-elements ${activeSection === 'logiciel' ? 'active' : ''}`}>
                    <div className='categories-product-elements-title'>
                        <h1>Logiciel</h1>
                        <img src={iconLogiciel} alt="img-category" />
                    </div>
                    <CustomListLayout listItems={logiciel} />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default BannerProduct;
