import React, { useEffect, useState } from 'react'
import CustomListBlog from '../../Layouts/CustomListBlog'
import Footer from '../../Layouts/Footer'
import NavBar from '../../Layouts/NavBar'
import Instance from '../../Services/Instance'
import config from "../../Services/config.js"
import { useParams } from 'react-router-dom'

function Post() {
    const [post, setPost] = useState({})
    const { id } = useParams()
    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/post/${id}`)
            setPost(result.data.post)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [id])
    // console.log(post);
    return (
        <div className='post'>
            <NavBar />
            <div className='post-content' >
                <div className='post-details'>
                    <img src={`${config.img_url}/${post.productImage}`} alt="chatgpt" className='img-post' id='post-content' />
                    <div className='description-card'>
                    <br/> <br/>
                        <h3 className='title-card'>{post.title} </h3>
                        <p id='text'>
                            <br />
                            {post.content}
                        </p>
                    </div>
                </div>
                {/*<div className='product-categories blog-categories cath-post'>

                    <ul>
                        <li>
                            <a href="#"> Actualités</a>
                        </li>

                        <li>
                            <a href="#music"> Music</a>
                        </li>

                        <li>
                            <a href="#video"> Vidéo</a>
                        </li>

                        <li>
                            <a href="#logiciel"> Logiciel</a>
                        </li>

                        <li>
                            <a href="#design"> Design</a>
                        </li>

                        <li>
                            <a href="#home"> Maison</a>
                        </li>

                        <li>
                            <a href="#education"> Education</a>
                        </li>

                        <li>
                            <a href="#cartes"> cartes</a>
                        </li>

                        <li>
                            <a href="#tv"> Télévisions</a>
                        </li>

                        <li>
                            <a href="#game"> Jeux</a>
                        </li>
                    </ul>
    </div>*/}

            </div>
            {/*<div className='similary' >
                <h1>Articles similaires</h1>
                <CustomListBlog />
</div>*/}
            <Footer />
        </div>
    )
}

export default Post