import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../Layouts/NavBar';
import iconWow from "../../Assets/Imgs/icons/icon-super.svg"
import iconPlus from "../../Assets/Imgs/icons/icon-plus.svg"
import iconShareDeco from "../../Assets/Imgs/icons/icon-share-deco.svg"
import InfiniteScrollAnimation from './InfiniteScrollAnimation ';
import VideoLoop from './VideoLoop';

function Banner() {
    return (
        <div className='banner home-banner'>
            {/*<VideoLoop className='video-banner' />*/}
            <div className='banner-home'>
                <div className='layout-banner'>
                    <div className='top-banner'>
                        <div className='imgs-deco'>
                            <img src={iconWow} alt="img-deco" />
                            <img src={iconPlus} alt="img-deco" />
                        </div>
                        <h1> <span id='services'> + 100 Services</span> <span id='dispo'>Disponibles;</span> abonnements, <span id='abonnement'>cartes cadeaux, jeux, </span> et bien encore plus.</h1>
                        <img src={iconShareDeco} alt="img-deco" className='icon-share-deco' />
                    </div>
                    <div className='banner-home-btn'>
                        <Link to='/products' className='banner-home-link' id='btn-banner'>
                            Voir tous les abonnements
                        </Link>
                    </div>
                </div>
                <div className='scroll-banner-home'>
                    <InfiniteScrollAnimation />
                </div>
            </div>
        </div>
    );
}

export default Banner;
