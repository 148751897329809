import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Assets/Imgs/icons/logo-senpremium2.svg';
import logoMobile from '../Assets/Imgs/icons/logo senpremium.png'
import { DataContext } from '../Services/DataContext';
import "../Assets/Styles/MainLayout.css"
import sousMenu from "../Assets/Imgs/icons/sous-menu.svg"
import { decryptData } from '../Services/EncryptData';

function NavBar() {
  const { toggleBooleanValue } = useContext(DataContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const user = localStorage.getItem("user")
  const dataUser = user === null ? null : decryptData(user)

  const handleLogout = (e) => {
    e.preventDefault();
    // Supprimer le token et les informations de l'utilisateur du LocalStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('tokenExpiration');
    toggleBooleanValue();
    window.location.href = '/';
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  //Switch to mobile menu
  useEffect(() => {
    const sousMenu = document.querySelector(".sous-menu");
    const navLinks = document.querySelector(".connection-link");

    const handleClick = () => {
      navLinks.classList.toggle('mobile-menu');
    };

    sousMenu.addEventListener('click', handleClick);

    return () => {
      sousMenu.removeEventListener('click', handleClick);
    };
  }, []); // Exécute une seule fois après le montage

  return (
    <div className='nav-bar'>
      <div className='nav-bar-responsive'>
        <Link to='/'>
        <img src={logo} alt='logo' className='logo' />
        <img src={logoMobile} alt='logo' className='logo-mobile' />
        </Link>
        <img src={sousMenu} alt="sous-menu" className='sous-menu' />
      </div>

      <div className='connection-link'>
        <Link to='/products' className='link-nav-bar'>
          Abonnements
        </Link>
        <Link to='/blog' className='link-nav-bar'>
          Blog
        </Link>
        <div className='btn-main-layout connection'>
          {dataUser ? (
            <div className='dropdown'>
              <span>
                <Link onClick={toggleDropdown} className='dropdown-compte dropdown-item'>
                  Compte
                </Link>
              </span>
              {isDropdownOpen && (
                <div className='dropdown-content'>
                <hr/>
                <Link to='/user-subscriptions' className='dropdown-item'>
                    Mes Abonnements
                  </Link>
                  <hr/>
                  <Link to='/user-shares' className='dropdown-item'>
                    Partager un abonnement
                  </Link>
                  <hr/>

                  <Link to={`/profile/${dataUser._id}`} className='dropdown-item'>
                    Profil
                  </Link>
                  <hr/>

                  <Link onClick={handleLogout} className='dropdown-item'>
                    Déconnexion
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <Link to='/signin' className='general-btn-link link-nav-bar' id='btn-connection'>
              Connexion
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
