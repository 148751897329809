import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from "../../Services/config.js";
import Instance from '../../Services/Instance';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import "../../Assets/Styles/Subscriptions.css";
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg";
import Footer from '../../Layouts/Footer';
import { DataContext } from '../../Services/DataContext';
import { v4 as uuidv4 } from 'uuid';
import arrowIcon from "../../Assets/Imgs/icons/right-arrow-circular-symbol-svgrepo-com.svg";
import Modal from 'react-modal';
import iconClose from "../../Assets/Imgs/icons/icon-close.svg"
import iconSignin from "../../Assets/Imgs/icons/icon-seconnecter.svg"
import ReactPixel from 'react-facebook-pixel';
import axios from "axios"
// const ipInfo = require("ipinfo")

// Définissez l'élément d'accessibilité pour le modal
Modal.setAppElement('#root');

function UnknownNewSubscription() {
    const [error, setError] = useState("");
    const [reNewal, setReNewal] = useState(false);
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement
    const [newSubscription, setNewSubscription] = useState({
        subscriptionDate: new Date(),
        subscriptionName: "",
        subscriptionType: "",
        productTypeSmart: "",
        CategoryName: "",
        subscriptionQuantity: "",
        subscriptionPrice: "",
        smartTvPrice: "",
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userNumberPhone: "",
        userCountry: "",
        confirmEmail: ""
    });

    const { id } = useParams();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewSubscription({ ...newSubscription, [name]: value });
    };

    // Get IP Address and location  
    const [geoLocation, setGeoLocation] = useState(null);

    // Get IP Address and location  
    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ip = response.data.ip;
                if (ip) {
                    const geoResponse = await Instance.get(`${config.api_url}/get-api/${ip}`);
                    console.log(geoResponse.data);
                    setGeoLocation(`${geoResponse.data.country_name}`);
                }
            } catch (error) {
                console.error('Error fetching IP address', error);
                setError('Network Error: Unable to fetch IP address.');
            }
        };

        fetchIpAddress();
    }, []);

    // const geoLocation = geoLocation ? `${geoLocation.country}-${geoLocation.region}` : null;

    // Recup product
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                setProduct(result.data.product);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [id]);

    // Génération de la référence de commande
    function generateRefCommand() {
        const uniqueId = uuidv4();
        const refCommand = uniqueId.split('-').slice(0, 4).join('').substring(0, 12);
        return refCommand;
    }

    // Incrémentation de la quantité
    const increment = () => {
        if (quantity < 3) {
            setQuantity(quantity + 1);
        }
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    // Recupérer le prix du smart tv 
    const smarTvPrice = localStorage.getItem('smartTvPrice');
    let netflixType = localStorage.getItem("netflixType")

    let optionSub = "";

    const priceSub = smarTvPrice ? smarTvPrice : product.productPrice;
    if (netflixType) {
        optionSub = `- ${netflixType}`
    } else if (!netflixType && product.productName && product.productName.toLocaleLowerCase().includes("netflix") && !product.productName.includes("NETFLIX Pack Family") && !product.productName.includes("Netflix Duo")) {
        optionSub = "- Phone/Pc"
    }
    // console.log(smarTvPrice);

    const { userFirstName, userLastName, userEmail, confirmEmail, userNumberPhone, userCountry } = newSubscription;

    const handlePayment = async (e) => {
        e.preventDefault(); // Empêche le rechargement de la page par défaut du formulaire

        const proceedToPayment = async () => {
            if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire

            try {
                setPaymentInProgress(true); // Définir l'état de la demande de paiement en cours
                const orderRef = generateRefCommand();

                const orderRequest = {
                    item_name: `${product.productName} ${optionSub}`,
                    item_price: quantity * priceSub,
                    ref_command: orderRef,
                    custom_field: ({
                        subscriptionDate: new Date(),
                        subscriptionName: `${product.productName} ${optionSub}`,
                        subscriptionType: product.productType,
                        productTypeSmart: optionSub,
                        subscriptionRef: orderRef,
                        subscriptionKey: "",
                        policy: "",
                        CategoryName: product.productCategory,
                        productImage: product.productImage,
                        productId: product._id,
                        subscriptionQuantity: quantity,
                        subscriptionPrice: quantity * priceSub,
                        userFirstName: userFirstName,
                        userLastName: userLastName,
                        userEmail: userEmail,
                        userNumberPhone: userNumberPhone,
                        userCountry: userCountry || 'Senegal',
                    })
                };
                const _response = await Instance.post(`${config.api_url}/payment`, orderRequest);
                if (_response.data.jsonResponse.success) {
                    const paymentUrl = _response.data.jsonResponse.redirect_url;
                    await handleSubscriptionRequest(orderRequest.custom_field);
                    window.location.href = paymentUrl;
                }
            } catch (error) {
                console.error('Erreur lors de la demande de paiement :', error);
                // alert("Vous avez entré des données incorrectes, veuillez réessayer.");
                // window.location.reload();
            } finally {
                setPaymentInProgress(false); // Définir l'état de la demande de paiement terminée
            }
        };

        const handleSubscriptionRequest = async (orderRequest) => {
            try {
                const subscriptionResponse = await Instance.post(`${config.api_url}/new-subscription`, orderRequest);
                // console.log('Subscription Response:', subscriptionResponse);
                return subscriptionResponse;
            } catch (error) {
                console.error('Erreur lors de la demande d\'abonnement :', error);
                throw error;
            }
        };

        const checkbox = document.getElementById("checkbox");
        if (checkbox.checked && newSubscription.userEmail === newSubscription.confirmEmail) {
            proceedToPayment();
        } else if (!checkbox.checked && newSubscription.userEmail === newSubscription.confirmEmail) {
            alert("Vous devez d'abord accepter les CGU de Senpremium avant de valider un paiement");
        } else if (checkbox.checked && newSubscription.userEmail !== newSubscription.confirmEmail) {
            alert("Vous devez d'abord passer les mêmes emails avant de valider un paiement");
        } else if (checkbox.checked && newSubscription.userEmail === null) {
            alert("Vous devez d'abord passer les infos de paiement avant de valider un paiement");
        }
    };

    // Demander à l'utilisateur s'il va faire un renouvellement ou pas
    const handleReNewal = () => { setReNewal(!reNewal) };

    //Meta pixel for facebook
    // const PixelMetaPayment = () => {
    //     ReactPixel.track('Purchase', {
    //         value: priceSub ,
    //         currency: 'XOF',
    //     });
    // };


    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>

            <div className='subscription-content-unknownSub'>
                <div className='subscription-content-layout content-left-unknownSub'>
                    {
                        !reNewal &&
                        <form onSubmit={handlePayment}>
                            <div className='options-subscription'>
                                <div type="button" onClick={handleReNewal} className='options-subscription-button'>
                                    <p>Souhaitez-vous faire <br /> un renouvellement ?</p>
                                    <img className='img-arrow' src={arrowIcon} alt="arrow" />
                                </div>
                                <h4>Nouveau Abonnement : Entrez vos informations</h4>
                            </div>
                            <div className='elements-from'>
                                <div>
                                    <label>Prénom</label>
                                    <input
                                        name="userFirstName"
                                        value={userFirstName}
                                        type="text"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Nom</label>
                                    <input
                                        name="userLastName"
                                        value={userLastName}
                                        onChange={handleChange}
                                        type="text"
                                        required
                                    />
                                </div>
                            </div>
                            <div className='elements-from'>
                                <div>
                                    <label>Pays</label>
                                    <input
                                        name="userCountry"
                                        value={!geoLocation ? userCountry : geoLocation}
                                        type="text"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Téléphone</label>
                                    <input
                                        name="userNumberPhone"
                                        value={userNumberPhone}
                                        type="text"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    name="userEmail"
                                    value={userEmail}
                                    type="email"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Confirmez votre Email</label>
                                <input
                                    name="confirmEmail"
                                    type="email"
                                    value={confirmEmail}
                                    pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {
                                newSubscription.confirmEmail && newSubscription.userEmail !== newSubscription.confirmEmail &&
                                (
                                    <h6 id='confirm-email'>Vous avez renseigné des emails différents</h6>
                                )
                            }
                            <div id='cgu'>
                                <div className='checkbox'>
                                    <input
                                        id='checkbox'
                                        name="CGU"
                                        type="checkbox"
                                        required
                                    />
                                    <label>J’ai lu et j’accepte les <Link to="/help-center/CGU">CGU</Link> de Senpremium.</label>
                                </div>
                                <p>Vos informations personnelles seront utilisées pour valider votre commande.</p>
                            </div>
                            <button type='submit' className='validate-subscription-btn' id='btn-payer'>
                                {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                            </button>
                        </form>
                    }
                </div>
                <div className='subscription-content-layout content-right-unknownSub'>
                    <h4>Détails de la commande</h4>
                    <div className='img-product'>
                        <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                    </div>
                    <div className='subscription-options'>
                        <div className='subscription-options-details'>
                            <div>
                                <h3>{product.productName} {optionSub}</h3>
                                {!smarTvPrice && <h3> FCFA {product.productPrice} </h3>}
                                {smarTvPrice && <h3> FCFA {smarTvPrice} </h3>}
                            </div>
                            <div className='counter'>
                                <button type="button" onClick={decrement} className='counter-params'>-</button>
                                <button type="button" className='counter-params' id='quantity'>{quantity}</button>
                                <button type="button" onClick={increment} className='counter-params'>+</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='subscription-options-details sub-total'>
                        <p>Sub Total </p>
                        {!smarTvPrice && <h3> {product.productPrice * quantity} FCFA </h3>}
                        {smarTvPrice && <h3> {smarTvPrice * quantity} FCFA </h3>}
                    </div>
                    <div className='subscription-options-details frais-payment'>
                        <p>Frais Paiement </p>
                        <h3> 2%</h3>
                    </div>
                    <div className='subscription-options-details sub-total'>
                        <p>Total </p>
                        {!smarTvPrice && <h3> {(product.productPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                        {smarTvPrice && <h3> {(smarTvPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}                    </div>
                </div>
            </div>
            <Footer />

            <Modal
                isOpen={reNewal}
                onRequestClose={handleReNewal}
                contentLabel="Réabonnement Modal"
                className="modal"
                overlayClassName="overlay"
            >
                <div className='wish-renewal'>
                    <div className='wish-renewal-title'>
                        <h2>Faire un réabonnement avec Senpremium.</h2>

                    </div>
                    <div className='wish-renewal-details'>
                        <div>
                            <h3> 1. Connexion/Inscription</h3>
                            <p>Cliquez sur "Connexion" pour vous connecter ou créer un compte.</p>
                        </div>

                        <div>
                            <h3> 2. Mes Abonnements</h3>
                            <p>Allez dans "Mes Abonnements" via "Compte" dans la barre de navigation.                            </p>
                        </div>
                        <div>
                            <h3> 3. Retrouver vos abonnements</h3>
                            <p>Consultez tous vos abonnements antérieurs, même sans compte</p>
                        </div>
                        <div>
                            <h3> 4. Effectuer votre réabonnement</h3>
                            <p>Sélectionnez et réabonnez-vous.</p>
                        </div>
                    </div>
                    <div className='wish-renewal-old'>
                        <div className='line'></div>
                        <div>
                            <h3>Pour les anciens utilisateurs de Senpremium :</h3>
                            <p>Pour conserver vos anciens accès, connectez-vous et recherchez l'abonnement correspondant. Fournissez les informations de votre commande et les anciens accès sur la page de paiement.</p>

                        </div>
                    </div>
                    <div className='wish-renewal-btn'>
                        <button onClick={handleReNewal} className='link-btn-wish link-btn-close'> <img src={iconClose} /> <span>Fermer</span> </button>
                        <Link to="/signin" className='link-btn-wish'><img src={iconSignin} /> <span>Se Connecter</span> </Link>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default UnknownNewSubscription;
