import React, { useEffect, useState } from 'react'
import NavBar from '../../Layouts/NavBar';
import "../../Assets/Styles/Compte.css"
import Instance from '../../Services/Instance';
import config from "../../Services/config.js";
import { useParams } from 'react-router-dom';

function EditInfos() {
    const { id } = useParams();
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        numberPhone: "",
        email: "",
    });

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-user/${id}`)
            // console.log(result.data.user);
            setUser(result.data.user)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [id])


    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.put(`${config.api_url}/update-user/${id}`, user);
            window.location = `/profile/${id}`;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='edit-user'>
            <NavBar />
            <div className='edit-user-details'>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='inputs-form'>
                        <div>
                            <label htmlFor="firstName">Prénom</label>
                            <input
                                className="input"
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={user.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName">Nom</label>
                            <input
                                className="input"
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={user.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="numberPhone">Téléphone</label>
                            <input
                                className="input"
                                type="text"
                                id="numberPhone"
                                name="numberPhone"
                                value={user.numberPhone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input
                                className="input"
                                type="email"
                                id="email"
                                name="email"
                                value={user.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='btn-submit'>
                        <button type="submit" className="link-submit">Editer</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditInfos;
