import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Instance from '../../Services/Instance';
import config from '../../Services/config.js';
import { DataContext } from '../../Services/DataContext';
import logo from '../../Assets/Imgs/icons/logo senpremium.png';
import { encryptData } from '../../Services/EncryptData.js';
import '../../Assets/Styles/Connection.css';

const SignIn = () => {
    const [data, setData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const { toggleBooleanValue } = useContext(DataContext);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || "/";

    useEffect(() => {
        checkTokenExpiration();
        handleRedirectAfterReload();
    }, []);

    const checkTokenExpiration = () => {
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        if (tokenExpiration) {
            const expirationTime = parseInt(tokenExpiration);
            const currentTime = new Date().getTime();
            if (currentTime > expirationTime) {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                localStorage.removeItem('user');
            }
        }
    };

    const handleRedirectAfterReload = () => {
        const redirectTo = localStorage.getItem('redirectTo');
        if (redirectTo) {
            localStorage.removeItem('redirectTo');
            navigate(redirectTo, { replace: true });
        }
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.api_url}/signin`;
            const { data: response } = await Instance.post(url, data);

            if (response) {
                const user = response.user;
                if (user) {
                    setError("");
                    toggleBooleanValue();
                    const encryptToken = encryptData(response.token);
                    const encryptUser = encryptData(user);
                    localStorage.setItem("token", encryptToken);
                    localStorage.setItem("user", encryptUser);
                    const expiresIn = 86400;
                    const expirationTime = new Date().getTime() + expiresIn * 1000;
                    localStorage.setItem("tokenExpiration", expirationTime);
                    localStorage.setItem('redirectTo', from);
                    window.location.reload();
                }
            } else {
                setError("Réponse invalide du serveur");
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            setError(error);
        }
    };

    return (
        <div className='layout-signin'>
            <div className='logo'>
                <Link to='/'>
                    <img src={logo} alt='logo' />
                </Link>
            </div>
            <div className='form-signin'>
                <form onSubmit={handleSubmit}>
                    <div className='parent-form-input'>
                        <label htmlFor='email'>Email:</label>
                        <input
                            className='input'
                            type='email'
                            name='email'
                            id='email'
                            value={data.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='parent-form-input'>
                        <label htmlFor='password'>Mot de passe:</label>
                        <input
                            className='input'
                            type='password'
                            name='password'
                            id='password'
                            value={data.password}
                            onChange={handleChange}
                            required
                        />
                        {error && <h6 style={{ color: 'red' }}>{error.response.data.message}</h6>}
                        <Link to="/resetpassword">Mot de passe oublier ?</Link>
                    </div>
                    <button type='submit' className='btn-form-connection' id='btn-signin'>
                        Se connecter
                    </button>
                </form>
                <div className='container-right-signin'>
                    <div className='card-title-signup sign-in'>
                        <p id='new-user'>Vous N’avez pas de compte ? </p>
                        <Link className='btn-form-connection' to='/signup'>
                            Inscrivez-vous
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
