import React, { useState, useEffect, useContext } from 'react';
import NavBar from '../../Layouts/NavBar'
import config from "../../Services/config.js"
import Instance from '../../Services/Instance'
import { Link } from 'react-router-dom';
import { DataContext } from '../../Services/DataContext';
import "../../Assets/Styles/Subscriptions.css"
import Footer from '../../Layouts/Footer';
import iconValidate from "../../Assets/Imgs/icons/Success Icon.svg"
import information from "../../Assets/Imgs/icons/attention.svg"
import status from "../../Assets/Imgs/icons/Status.svg"
import download from "../../Assets/Imgs/icons/loading2.gif"
import arrowRight from "../../Assets/Imgs/icons/arrow-right-circle-line-black.png"

function ValidateSubscription() {
  const { clearItem, orderInformation, setOrderInformation } = useContext(DataContext);
  const [error, setError] = useState(null)
  const [order, setOrder] = useState({})
  const [valueFromURL, setValueFromURL] = useState('');
  const [subscriptionKeyDefined, setSubscriptionKeyDefined] = useState(false);
  const [countdown, setCountdown] = useState(10);


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get('value');
    setValueFromURL(value || '');
  }, []);
  const _idOrder = valueFromURL 
  const smartTvPrice = localStorage.getItem("smartTvPrice")
  if (smartTvPrice) {
    localStorage.removeItem('smartTvPrice')
    localStorage.removeItem('netflixType')
  }
  useEffect(() => {
    if (_idOrder) {
      fetchDataNewOrder();

    }
  }, [_idOrder]);

  const fetchDataNewOrder = async () => {
    try {
      const result = await Instance.get(`${config.api_url}/show-subscription/${_idOrder}`);
      setOrder(result.data.subscription);
      if (result.data.subscription.subscriptionKey !== "" && result.data.subscription.subscriptionKey !== null) {
        setSubscriptionKeyDefined(true);
      } else {
        ReloadComponent()
      }
    } catch (error) {
      console.error("-+-+-+-Erreur lors de la récupération des subscribers:", error);
      setError(error);
    }
  };

  const handleClose = () => {
    clearItem();
  };

  function extraireDateHeure(dateISO) {
    const date = new Date(dateISO);
    return date.toLocaleString('fr-FR', { timeZone: 'UTC', dateStyle: 'short', timeStyle: 'short' });
  }

  const OrderInfos = () => {
    // window.location.reload();
    setOrderInformation(true)

  }

  useEffect(() => {
    // Définir un timeout pour afficher les informations de la commande après 5 secondes
    const autoShowOrderInfo = setTimeout(() => {
      OrderInfos();
    }, 5000);

    // Nettoyer le timeout si le composant est démonté ou si le bouton est cliqué
    return () => clearTimeout(autoShowOrderInfo);
  }, []);

  //Compteur pour recharger les données au cas le chargement est lent 

  const ReloadComponent = () => {
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 0) {
          window.location.reload();
          // Réinitialiser le compteur à 5 lorsqu'il atteint 0
          return 10;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000); // Mettre à jour le compteur toutes les secondes

    return intervalId; // Retourner l'ID de l'intervalle pour pouvoir le nettoyer plus tard
  }


  if ((!_idOrder)) {
    return (
      <div className='inprogress'>
        <img src={download} alt="" />
        <p>Paiement incomplète </p>
        <Link to="/" onClick={handleClose}>Fermer</Link>
      </div>
    );
  }
  // console.log( "+++", subscriptionKeyDefined);
  return (
    <div className='subscription'>
      <NavBar />
      <div className='success-subscription-layout'>
        <div className='subscription-content-layout content-success-left' >

          <div className='img-success-subscription'>
            <img src={`${config.img_url}/${order.productImage}`} alt="img-product" />
            <p>Merci pour votre commande,Voici les détails de votre commande</p>
          </div>

          <h4>Informations de l'abonnement</h4>
          <div className='subscription-options-details sub-total'>
            <p>Type de l'abonnement </p>
            <h4> {order.subscriptionType || "Simple"} </h4>
          </div>

          <div className='subscription-options-details frais-payment'>
            <p>Prix  </p>
            <h4> {order.subscriptionPrice / order.subscriptionQuantity} FCFA</h4>
          </div>

          <div className='subscription-options-details sub-total'>
            <p> Quantité </p>
            <h4> {order.subscriptionQuantity} </h4>
          </div>

          <div className='subscription-options-details sub-total'>
            <p> Total </p>
            <h4>{order.subscriptionPrice}  FCFA </h4>
          </div>

          <div className='validate-subscription-infos'>
            <Link to="/" onClick={() => { handleClose() }} className='validate-subscription-btn' id='btn-close'>
              Fermer
            </Link>
          </div>

        </div>

        <div className='subscription-content-layout content-success-right'>

          <div className='details-content-success order-validate'>
            <img src={iconValidate} alt="validate" />
            {
              _idOrder.includes("ReNew") ?
                <p>Votre Renouvellement a été validé avec succès! !</p>
                :
                <p>Votre abonnement a été validé avec succès! !</p>

            }
            <h3> {order.subscriptionPrice}  FCFA </h3>
          </div>

          {
            order && subscriptionKeyDefined && orderInformation ?
              (
                <div className='details-content-success order-information'>
                  <h5> {order.subscriptionKey} </h5>
                </div>
              )
              : order && subscriptionKeyDefined && !orderInformation ?
                (
                  <div className='details-content-success order-information'>
                    <img src={information} alt='information' />
                    <div>
                      <h5>Informations de votre commande</h5>
                      <p>Ici vous trouverez les identifiants de connexion au compte</p>
                    </div>
                    <img src={arrowRight} alt='arrow' onClick={OrderInfos} />
                  </div>
                )
                :
                (
                  <div className='in-progress-content'>
                    <img src={download} className="loading-gif" alt="" />
                    <h5>
                      Votre commande est en cours de chargement...
                    </h5>
                    <h5> {countdown} </h5>
                  </div>
                )

          }

          <div className='details-content-success order-details'>
            <h4>Détails du paiement</h4>

            <div className='subscription-options-details'>
              <p> N° Commande </p>
              <h4> {order.subscriptionRef}</h4>
            </div>

            <div className='subscription-options-details'>
              <p> Status de paiement </p>
              <img src={status} alt="status" />
            </div>

            <div className='subscription-options-details'>
              <p> Date de Paiement </p>
              <h4> {extraireDateHeure(order.subscriptionDate)} </h4>
            </div>
            <h4>Terme de Commande </h4>
            <p>{order.policy || "Les termes sont en cours de téléchargements..."}</p>

            <p id='infos-send-email'>Ces informations vous seront envoyés par email aussi</p>

          </div>
        </div>

      </div>

      <div className='footer'>
        <Footer />
      </div>
    </div>
  )
}



export default ValidateSubscription;
