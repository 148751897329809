import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from "../Services/config.js";
import "../Assets/Styles/MainLayout.css";
import "../Assets/Styles/Home.css";
import "../Assets/Styles/Products.css";
import next from "../Assets/Imgs/icons/round-arrow-right-icon.svg";
import backTo from "../Assets/Imgs/icons/round-arrow-left-icon.svg";

function CustomListLayout({ listItems }) {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listItems.slice(indexOfFirstItem, indexOfLastItem);

  const shuffleArray = (array) => {
    let shuffledArray = array.slice(); // Create a copy of the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const shuffledItems = shuffleArray(currentItems);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setItemsPerPage(4);
      } else if (window.innerWidth <= 768) {
        setItemsPerPage(3);
      } else if (window.innerWidth <= 1000) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(5);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='custom-list-layout'>
      <div className='list-elements-wrapper'>
        {currentPage > 1 && (
          <img src={backTo} alt="backbtn" onClick={prevPage} className='pagination left' />
        )}
        <div className='list-elements-details'>
          {shuffledItems.map((item, index) => (
            <div className='list-elements custom-list-details' key={index}>
              <div className='custom-list-details-card'>
                <div className='card-img'>
                  <img src={`${config.img_url}/${item.productImage}`} alt="img" className='img-product-list' />
                </div>
                <div className='card-details-elements'>
                  <h4>{item.productName}</h4>
                  <h5>{item.productType}</h5>
                  <div className='price-product'>
                    <span>{item.productPrice}FCFA</span>
                    {item.productName.toLocaleLowerCase().includes("netflix")
                      && !item.productName.includes("NETFLIX Pack Family")
                      && !item.productName.includes("Netflix Duo")
                      && <span>-{item.productPrice2}FCFA</span>}
                  </div>
                  {item.productInStock && item.productInStock === "Out of Stock" ?
                    <div className='product-rupture'> En Rupture </div>
                    :
                    (<div id='btn-subscription'>
                      {item.productCategory === "Cartes Cadeaux et Jeux Video" ? (
                        <Link to={`/subscription/${item._id}`} className='link-subscription' id='btn-banner'> Acheter </Link>
                      ) : (
                        <Link to={`/subscription/${item._id}`} className='link-subscription' id='btn-banner'> S'abonner </Link>
                      )}
                    </div>)
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
        {indexOfLastItem < listItems.length && (
          <img src={next} alt="nextbtn" onClick={nextPage} className='pagination right' />
        )}
      </div>
    </div>
  );
}

export default CustomListLayout;
