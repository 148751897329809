import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from '../../Services/config.js';
import Instance from '../../Services/Instance';
import { useParams } from 'react-router-dom';
import '../../Assets/Styles/Subscriptions.css';
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg"
import Footer from '../../Layouts/Footer';
import { v4 as uuidv4 } from 'uuid';
import { decryptData } from '../../Services/EncryptData.js';
import { DataContext } from '../../Services/DataContext';


function Renewal() {
    // Déclaration des états
    const [error, setError] = useState('');
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement

    // Récupération de l'identifiant du produit depuis les paramètres de l'URL
    const { ref } = useParams();

    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)


    // Effet de chargement initial pour récupérer les détails du produit
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-subscription/${ref}`);
                setProduct(result.data.subscription);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [ref]);

    const _date = new Date().toLocaleString('fr-FR');
    function formatDateTime(date) {
        const dateTime = new Date(date);

        // Extraire les différentes parties de la date et de l'heure pour un faire une ref de commande ajouter du mot clé ReNew
        const day = ('0' + dateTime.getDate()).slice(-2);
        const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
        const year = dateTime.getFullYear();
        const hours = ('0' + dateTime.getHours()).slice(-2);
        const minutes = ('0' + dateTime.getMinutes()).slice(-2);
        const seconds = ('0' + dateTime.getSeconds()).slice(-2);

        // Concaténer les parties ensemble sans séparateurs
        const formattedDateTime = `${day}${month}${year}${hours}${minutes}${seconds}`;

        return `ReNew${formattedDateTime}`;
    }
    // Génération de la référence de commande
    function generateRefCommand() {
        const uniqueId = uuidv4();
        const refCommand = uniqueId.split('-').slice(0, 4).join('').substring(0, 8);
        return `ReNew${refCommand}`;
    }
    // const _date = newDate.toLocaleDateString('fr-FR')

    // Incrémentation de la quantité
    const increment = () => {
        if (quantity < 3) {
            setQuantity(quantity + 1);
        }
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const smartTvPrice = localStorage.getItem("smartTvPrice")

    // Gestion de la demande de paiement
    const handlePaymentRequest = async () => {
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire
        try {
            setPaymentInProgress(true); // Définir l'état de la demande de paiement en cours
            const orderRef = generateRefCommand();

            const orderRequest = {
                item_name: product.subscriptionName,
                // Au cas l'abonnement était plus d'un mois (2 ou 3mois voir plus)
                item_price: quantity * (product.subscriptionPrice / product.subscriptionQuantity),
                ref_command: orderRef,
                custom_field: {
                    subscriptionDate: new Date(),
                    subscriptionName: product.subscriptionName,
                    subscriptionRef: orderRef,
                    subscriptionPrice: quantity * (product.subscriptionPrice / product.subscriptionQuantity),
                    subscriptionKey: product.subscriptionKey,
                    policy: product.policy,
                    subscriptionType: product.subscriptionType,
                    productTypeSmart: smartTvPrice || "",
                    CategoryName: product.CategoryName,
                    productId: product.productId,
                    productImage: product.productImage,
                    subscriptionQuantity: quantity,
                    subscriptionNumber: quantity,
                    userId: user._id,
                    userFirstName: user.firstName,
                    userLastName: user.lastName,
                    userEmail: user.email,
                    userNumberPhone: user.numberPhone,
                    userCountry: user.country || 'Senegal',
                }
            };

            const _response = await Instance.post(`${config.api_url}/payment`, orderRequest);
            // console.log("test",_response);

            if (_response.data.jsonResponse.success) {
                const paymentUrl = _response.data.jsonResponse.redirect_url;
                // console.log("new sub");

                // Appeler la deuxième fonction avec orderRef comme argument
                // console.log("------------",orderRef);

                await handleSubscriptionRequest(orderRequest.custom_field);
                // console.log("+++++++++++++++",orderRef);

                // Retourner l'URL de paiement
                window.location.href = paymentUrl
            }
        } catch (error) {
            console.error('Erreur lors de la demande de paiement :', error);
            // Gérer les erreurs de manière appropriée
            throw error;
        } finally {
            setPaymentInProgress(false); // Définir l'état de la demande de paiement terminée
        }
    };

    // Fonction pour effectuer la deuxième requête pour la demande d'abonnement
    const handleSubscriptionRequest = async (orderRequest) => {
        try {
            const subscriptionResponse = await Instance.post(`${config.api_url}/new-subscription`, orderRequest);
            console.log('Subscription Response:', subscriptionResponse);

            // Retourner la réponse de la deuxième requête
            return subscriptionResponse;
        } catch (error) {
            console.error('Erreur lors de la demande d\'abonnement :', error);
            // Gérer les erreurs de manière appropriée
            throw error;
        }
    };

    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>
            <div className='subscription-content-layout interne-subscription'>
                <h3>Détails de la commande</h3>
                <div className='img-product'>
                    <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                </div>

                <div className='subscription-options'>

                    <div className='subscription-options-details'>
                        <div>
                            <h3> {product.subscriptionName} </h3>
                            <h3> FCFA {(product.subscriptionPrice / product.subscriptionQuantity)} </h3>
                        </div>

                        <div className='counter'>
                            <button onClick={decrement} className='counter-params'>
                                -
                            </button>
                            <button className='counter-params' id='quantity'>
                                {quantity}
                            </button>
                            <button onClick={increment} className='counter-params'>
                                +
                            </button>
                        </div>
                    </div>

                </div>

                <hr />

                <div className='subscription-options-details sub-total'>
                    <p>Sub Total </p>
                    <h3> {(product.subscriptionPrice / product.subscriptionQuantity) * quantity} FCFA </h3>
                </div>

                <div className='subscription-options-details frais-payment'>
                    <p>Frais Paiment </p>
                    <h3> 1%</h3>
                </div>

                <div className='subscription-options-details sub-total'>
                    <p> Total </p>
                    <h3> {((product.subscriptionPrice / product.subscriptionQuantity) * quantity) + (((product.subscriptionPrice / product.subscriptionQuantity) * quantity) / 100)} FCFA </h3>
                </div>

                <button onClick={handlePaymentRequest} className='validate-subscription-btn' id='btn-payer' disabled={paymentInProgress}>
                    {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                </button>

            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    );
}

export default Renewal;
