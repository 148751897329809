import React from 'react'
import sureService from "../../Assets/Imgs/templates/sureService.png"
import SecurePayment from "../../Assets/Imgs/templates/SecurePayment.png"
import economy from "../../Assets/Imgs/templates/economy.png"
import talk1 from "../../Assets/Imgs/icons/talk1.svg"
import talk2 from "../../Assets/Imgs/icons/talk2.svg"
import talk3 from "../../Assets/Imgs/icons/talk3.svg"
import talk4 from "../../Assets/Imgs/icons/talk4.svg"
import talk5 from "../../Assets/Imgs/icons/talk5.svg"
import talk6 from "../../Assets/Imgs/icons/talk6.svg"
import talk7 from "../../Assets/Imgs/icons/talk7.svg"
import talk8 from "../../Assets/Imgs/icons/talk8.svg"
import banner from "../../Assets/Imgs/templates/banner_2.png"
function AboutUs() {
    return (
        <div className='about-us'>
            {/*<div className='home-trust'>
                <h2> Ils parlent <span id='how'> de Nous</span> </h2>
            </div>
            <div className='talk-about-us'>
                <img src={talk1} alt="talk-img" />
                <img src={talk2} alt="talk-img" />
                <img src={talk3} alt="talk-img" />
                <img src={talk4} alt="talk-img" />
                <img src={talk5} alt="talk-img" />
                <img src={talk6} alt="talk-img" />
                <img src={talk7} alt="talk-img" />
                <img src={talk8} alt="talk-img" />
    </div>*/}

            {/* A propos de nous  */}
            <div className='home-trust about-us-description'>
                <img src={banner} alt="logo" />
                <div className='about-us-description-right'>
                    <h1> À propos de Nous</h1>
                    <p className='text-home-trust'>
                        Senpremium, votre destination en ligne pour l’achat et la vente d’abonnements à une gamme étendue de services ainsi que des cartes cadeaux numériques pour des plateformes populaires telles que Netflix, Prime Video, Spotify, PSN, iTunes, et plus encore. Notre site web est conçu pour offrir une interface utilisateur conviviale qui facilite l’accès à une variété d’abonnements, garantissant une expérience d’achat fluide, sécurisée et fiable.
                    </p>
                </div>
            </div>

            <div className='custom-card'>
                <div className='card'>
                    <div className='top-element'>
                        <img src={sureService} alt="user" className='img-card' />
                        <h3 className='title-card'>Garantie de service </h3>
                    </div>
                    <div className='description-card'>
                        <p>
                            Chaque commande effectuée sur notre site bénéficie d'une garantie pour la durée payée. En cas de problème, notre équipe effectuera une analyse détaillée et, si nécessaire, un remboursement sera effectué.
                        </p>
                    </div>
                </div>

                <div className='card'>
                    <div className='top-element'>
                        <img src={SecurePayment} alt="user" className='img-card' />
                        <h3 className='title-card'>Paiement sécurisé </h3>
                    </div>
                    <div className='description-card'>
                        <p>
                            Notre plateforme et l'ensemble des transactions sont protégées par un certificat de sécurité SSL, grâce à notre partenaire Paytech, assurant une sécurité optimale.
                        </p>
                    </div>
                </div>

                <div className='card'>
                    <div className='top-element'>
                        <img src={economy} alt="user" className='img-card' />
                        <h3 className='title-card'>Économie garantie</h3>
                    </div>
                    <div className='description-card'>
                        <p>
                            Avec Senpremium, dépensez moins et profitez davantage. Réalisez des économies allant jusqu'à 60% sur vos abonnements.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutUs