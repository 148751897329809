import React , {useEffect} from "react";
import Banner from "../Components/Home/Banner";
import HowItWorks from "../Components/Home/HowItWorks";
import AboutUs from "../Components/Home/AboutUs";
import Footer from "../Layouts/Footer";
import "../Assets/Styles/Home.css"
import SubShare from "../Components/Home/SubShare";
import NavBar from "../Layouts/NavBar";
import { decryptData } from "../Services/EncryptData";
// import Facebook from "../Services/Facebook";


function Home() {

  const _user = localStorage.getItem('user');
  const user = _user === null ? null : decryptData(_user)

  // console.log(user);
  user ?
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "acvunhpb",
    name: `${user.firstName} ${user.lastName}` , // Nom complet
    email: user.email  , // l’e-mail de votre utilisateur
    created_at: user.createdAt  , // Date d’inscription en tant qu’horodatage Unix
  })
  : 
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "acvunhpb",
    name: "Boss", // Nom complet
  })
  window.Intercom("update");
  window.scrollTo(0, 0);

      // Vérifie l'expiration du token lors du chargement initial du composant
      useEffect(() => {
        checkTokenExpiration();
    }, []);

    // Fonction pour vérifier l'expiration du token
    const checkTokenExpiration = () => {
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        if (tokenExpiration) {
            const expirationTime = parseInt(tokenExpiration);
            const currentTime = new Date().getTime();
            if (currentTime > expirationTime) {
                // Supprimer le token et déconnecter l'utilisateur
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                localStorage.removeItem('user');
            }
        }
    };

  return (
    <div className="home">
      <NavBar />
      <Banner />
      <SubShare />
      <HowItWorks />
      <AboutUs />
      <Footer />
    </div>
  )
}

export default Home