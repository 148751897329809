import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../Assets/Imgs//icons/logo-senpremium2.svg"
import logoMobile from '../Assets/Imgs/icons/logo senpremium.png'
import twitter from "../Assets/Imgs/icons/icon-twitter2.svg"
import insta from "../Assets/Imgs/icons/icon-instagram2.svg"
import tiktok from "../Assets/Imgs/icons/icon-tiktok2.svg"
import whatsapp from "../Assets/Imgs/icons/icon-whatsap.png"
import fb from "../Assets/Imgs/icons/icon-facebook2.svg"
import iconHeart from "../Assets/Imgs/icons/heart-svgrepo-com.svg"

function Footer() {
  return (
    <div className='footer'>
      <div className="social">
        <Link className="img-rs" to="https://x.com/senpremium1" > <img src={twitter} alt="rs" /> </Link>
        <Link className="img-rs" to="https://www.instagram.com/senpremium__" > <img src={insta} alt="rs" /> </Link>
        <Link className="img-rs" to="https://www.tiktok.com/@senpremium.com?_t=8mt75tj5iWE&_r=1" > <img src={tiktok} alt="rs" /> </Link>
        <Link className="img-rs" to="https://m.facebook.com/profile.php?id=61553142975625&mibextid=LQQJ4d" > <img src={fb} alt="rs" /> </Link>
      </div>
      <div className="logo">
        <img src={logoMobile} alt="logo" className='logo__footer' />
      </div>
      <div className="menu">
        <ul>
          <li><Link to="#" className="active">A propos</Link></li>
          {/*<li><Link to="#">Compte</Link></li>*/}
          <li><Link to="/help-center/refund-policy">Politique de remboursement</Link></li>
          <li><Link to="/help-center/CGU">Conditions d'utilisation générales</Link></li>
          <li><Link to="https://help-center.senpremium.com/" target="_blank">Centre d’aide</Link></li>
        </ul>
      </div>
      <div className="copyright">
        <div className='made'>Made with <img src={iconHeart} alt='icon-heart' />  in Senegal</div>
        <p>© copyright 2020 - 2024</p>
      </div>


    </div>
  )
}

export default Footer


// <div className='logo'>
//         <img src={logo} alt="logo" id='logo' />

//       </div>
//       <div className='container-copyright'>
//         <p className='copyright'>Made with <span className="heart-icon"></span> in Senegal</p>
//         <p className='copyright'>© 2024. SenPremium</p>
//       </div>
//       <div className='social-medias'>
//         <Link className="img-rs" to="https://t.me/senepremium1" > <img src={telegram} alt="rs" /> </Link>
//         <Link className="img-rs" to="https://www.instagram.com/senpremium__" > <img src={insta} alt="rs" /> </Link>
//         <Link className="img-rs" to="https://wa.me/message/ZXA2SQX2AWUSB1" > <img src={whatsapp} alt="rs" /> </Link>
//       </div>