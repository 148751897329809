import React, { useEffect, useState } from 'react';
import NavBar from '../Layouts/NavBar';
import config from "../Services/config.js";
import Instance from '../Services/Instance';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import Footer from "../Layouts/Footer.js";
import "../Assets/Styles/Subscriptions.css";
import delivery from "../Assets/Imgs/icons/icon-fast-delivery.svg";
import freeMoney from "../Assets/Imgs/icons/freemoney.PNG";
import wave from "../Assets/Imgs/icons/wave.PNG";
import masterCard from "../Assets/Imgs/icons/mastercard.PNG";
import visa from "../Assets/Imgs/icons/visa.PNG";
import om from "../Assets/Imgs/icons/orangemoney.PNG";
import mtn from "../Assets/Imgs/icons/mtn.PNG";
import moov from "../Assets/Imgs/icons/moovmoney.PNG";
import { decryptData } from '../Services/EncryptData.js';
import attentionIcon from "../Assets/Imgs/icons/infos avertissement.svg"
import iconPhonePcActive from "../Assets/Imgs/icons/btn_phonePc_active.png"
import iconPhonePcEnable from "../Assets/Imgs/icons/btn_phonePc_enable.png"
import iconSmartTVEnable from "../Assets/Imgs/icons/btn_smarttv_enable.png"
import iconSmartTVActive from "../Assets/Imgs/icons/btn_smarttv_active.png"


function Subscription() {
    const [error, setError] = useState("");
    const [product, setProduct] = useState([]);
    const [faqs, setFaqs] = useState([]);

    const { id } = useParams();
    const user__ = localStorage.getItem("user");
    const dataUser__ = user__ === null ? null : decryptData(user__);
    const [optionNetflix, setOptionNetflix] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                setProduct(result.data.product);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };
        window.scrollTo(0, 0);
        fetchData();
        optionPhonePc()
    }, [id]);


    const optionSmartTV = () => {
        setOptionNetflix(true)
        localStorage.setItem("smartTvPrice", product.productPrice2)
        localStorage.setItem("netflixType", "Smart TV")

    }
    const optionPhonePc = () => {
        setOptionNetflix(false)
        localStorage.removeItem("smartTvPrice")
        localStorage.removeItem("netflixType")

    }


    useEffect(() => {
        const defaultFaqs = product.faq || [];
        if (defaultFaqs.length > 0) {
            // Initialize the first FAQ with statusFaq: true
            defaultFaqs[0].statusFaq = true;
        }
        setFaqs(defaultFaqs.map(faq => ({ ...faq, statusFaq: faq.statusFaq || false })));
    }, [product]);

    const toggleFaq = (index) => {
        setFaqs(prevFaqs => prevFaqs.map((faq, i) => {
            if (i === index) {
                return { ...faq, statusFaq: !faq.statusFaq };
            } else {
                return faq;
            }
        }));
    };

    return (
        <div className='subscription'>
            <NavBar />
            <div className='subscription-content-layout subscription-delivery'>
                <img src={delivery} alt="delivery-img" />
                <p>Abonnement ou Code livré <b id='payment'>instantanément</b> sur votre écran et par email après le paiement </p>
            </div>
            <div className='subscription-content-layout subscription-container'>
                <div className='img-product'>
                    <img src={`${config.img_url}/${product.productImage}`} alt="img-product" />
                </div>
                <div className='subscription-content-details'>
                    <div className='content-layout content-left'>
                        <h2>Description</h2>
                        <p className='content-description'>{product.productDescription}</p>
                    </div>
                    <div className='content-layout content-right'>
                        <div className='content-right-child'>
                            <div>
                                <h4>Catégorie</h4>
                                <p className='content-description'>{product.productCategory}</p>
                            </div>
                            {product.productType &&
                                <div>
                                    <h4>Type d'Abonnement</h4>
                                    <p className='content-description'>{product.productType}</p>
                                </div>
                            }
                            <div>
                                <h2>Prix</h2>
                                {!optionNetflix && <p className='style-price'>{product.productPrice} FCFA</p>}
                                {optionNetflix && <p className='style-price'>{product.productPrice2} FCFA</p>}

                            </div>
                        </div>
                    </div>
                </div>
                <div className={product.productName
                    && product.productName.toLocaleLowerCase().includes("netflix")
                    && !product.productName.includes("NETFLIX Pack Family")
                    && !product.productName.includes("Netflix Duo")
                    ? 'type-abonnement' : "type-abonnemen-enable"}>
                    {product.productName && product.productName.toLocaleLowerCase().includes("netflix")
                        && !product.productName.includes("NETFLIX Pack Family")
                        && !product.productName.includes("Netflix Duo")
                        && (
                            <div>
                                <h2>Type d'abonnement</h2>
                                <div className='option-type-abonnement'>
                                    <img src={optionNetflix ? iconPhonePcEnable : iconPhonePcActive} alt="phone-pc" onClick={optionPhonePc} />
                                    <img src={optionNetflix ? iconSmartTVActive : iconSmartTVEnable} alt="smartTV" onClick={optionSmartTV} />
                                </div>
                            </div>
                        )}

                    <div className='validate-subscription-infos'>
                        <Link to={dataUser__ ? `/new-subscription/${product._id}` : `/unknown-new-subscription/${product._id}`} className='validate-subscription-btn'>Valider</Link>
                    </div>
                </div>
                {product.productName
                    && product.productName.toLocaleLowerCase().includes("netflix")
                    && !product.productName.includes("NETFLIX Pack Family")
                    && !product.productName.includes("Netflix Duo")
                    && <div className='attention'>
                        <img src={attentionIcon} alt="attention" />
                        {!optionNetflix && <p>  Cette offre est valable pour les <span className='option-netflix-active'>téléphones ou PC</span>. Si vous avez une Smart TV, veuillez choisir l'offre NETFLIX Smart TV </p>}
                        {optionNetflix && <p> Cette offre est valable pour les <span className='option-netflix-active'>Smart TV</span>. Si vous avez un téléphones ou PC, veuillez choisir l'offre NETFLIX Phone/Pc </p>}
                    </div>}

                {product.productAttention && <div className='attention'>
                    <img src={attentionIcon} alt="attention" />
                    <p className='avertissement'> {product.productAttention} </p>
                </div>}
            </div>

            <div className='subscription-content-layout subscription-container'>
                <h1 className='faq-title'>FAQ</h1>
                <div className='faq-content'>
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <div className='faq-item'>
                                <p>{faq.question}</p>
                                {faq.statusFaq ? (
                                    <h1 onClick={() => toggleFaq(index)} className='chevron arrow-faq'>⌵</h1>
                                ) : (
                                    <h1 onClick={() => toggleFaq(index)} className='arrow-faq'>⌵</h1>
                                )}
                            </div>
                            {faq.statusFaq && (
                                <p className='faq-description'>{faq.answer}</p>
                            )}
                            <hr />
                        </div>
                    ))}
                </div>
            </div>
            <div className='subscription-content-layout subscription-container content-payment'>
                <h2 className='title-payment'> <span id='payment'>Paiement</span> <span id='secure'>Sécurisé</span> </h2>
                <div className='payment-secure-img'>
                    <img src={wave} alt="img-payment" />
                    <img src={freeMoney} alt="img-payment" />
                    <img src={om} alt="img-payment" />
                    <img src={masterCard} alt="img-payment" />
                    <img src={visa} alt="img-payment" />
                    <img src={mtn} alt="img-payment" />
                    <img src={moov} alt="img-payment" />
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default Subscription;
