import React, { useEffect, useState } from 'react'
import NavBar from '../../Layouts/NavBar'
import "../../Assets/Styles/Connection.css"
import edit from "../../Assets/Imgs/edit.svg"
import { Link, useParams } from 'react-router-dom'
import Footer from '../../Layouts/Footer'
import Instance from '../../Services/Instance'
import config from "../../Services/config.js"
import { decryptData } from '../../Services/EncryptData.js'

function Compte() {

    const user__ = localStorage.getItem("user")
    const dataUser__ = user__ === null ? null : decryptData(user__)

    const { id } = useParams();
    const [dataUser, setDataUser] = useState({})

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-user/${id}`)
            setDataUser(result.data.user)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [id])



    return (
        <div className='subscription'>
            <NavBar />
            <div className=' compte-layout'>
                <div className='compte-user'>
                    <div>
                        <h1>
                            {`${dataUser__.firstName.charAt(0).toUpperCase()}.${dataUser__.lastName.charAt(0).toUpperCase()}`}
                        </h1>
                    </div>
                    <h2> {dataUser.firstName} {dataUser.lastName}</h2>
                    <Link to={`/edit-user/${dataUser._id}`}>
                        <img src={edit} alt="edit" />
                    </Link>
                </div>
                <div className='more-infos'>
                    <p> email :  <span>{dataUser.email}</span> </p>
                    <p> Téléphone :  <span>{dataUser.numberPhone}</span> </p>
                </div>

            </div>
            <div className='footer'>
                <Footer />

            </div>
        </div>
    )
}

export default Compte