import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../../Services/DataContext'
import config from '../../Services/config.js';
import Instance from '../../Services/Instance';

function ShareResume() {

  const { formShare } = useContext(DataContext);
// console.log(formShare);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire
    try {
      const postResult = await Instance.post(`${config.api_url}/new-share`, {

        shareName: formShare.shareName,
        shareType: formShare.shareType,
        shareQuantity: formShare.shareQuantity,
        sharePrice: formShare.sharePrice,
        shareImg: formShare.shareImg,
        shareEmailProduct: formShare.shareEmailProduct,
        sharePasseWordProduct: formShare.sharePasseWordProduct,
        CategoryName: formShare.CategoryName,

      });
      window.location = "/user-shares";

    } catch (error) {
      // setError(error);
      console.log(error);
    }
  }
// console.log(formShare.shareImg);
  return (
    <div>

      <div className='share-resume '>
        <div className='card-resume'>
          <img src={`${config.img_url}/${formShare.shareImg}`} alt="img-share" className='img-share' />
          <h1> {formShare.shareName} </h1>
          <hr />
          <div className='details-share-resume'>
            <div className='elements-details-share-resume'>
              <p>Catégorie</p>
              <span> {formShare.CategoryName} </span>
            </div>
            <div className='elements-details-share-resume'>
              <p>Type</p>
              <span> {formShare.shareType} </span>
            </div>
            <div className='elements-details-share-resume'>
              <p>Mensualité / utilisateur</p>
              <span> {formShare.sharePrice} F CFA</span>
            </div>
            <div className='elements-details-share-resume'>
              <p>Nobres d'Abonnements</p>
              <span> {formShare.shareQuantity} </span>
            </div>

            <div className='elements-details-share-resume'>
              <p>email du compte</p>
              <span> {formShare.shareEmailProduct} </span>
            </div>
            <div className='elements-details-share-resume'>
              <p>Mot de passe</p>
              <span> {formShare.sharePasseWordProduct} </span>
            </div>
          </div>
          <Link onClick={handleSubmit} to="/user-shares" className='btn-share' > Partager</Link>
        </div>
      </div>
    </div>
  )
}

export default ShareResume